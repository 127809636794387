<i18n>
{
  "nl-BE": {
    "report": "Rapport"
  },
  "en-BE": {
    "report": "Report"
  },
  "fr-BE": {
    "report": "Rapport"
  }
}
</i18n>

<template>
    <div id="sidebar">
        <div class="sidebar-back">
            <div class="container section p-3">
                <div class="columns is-multiline">
                    <div class="column is-12 pb-0">
                        <auto-complete
                            :placeholder="house.address_text.full_address"
                            size="is-normal"
                            @selectedItem="selectAddress($event)"
                        />
                    </div>
                    <div class="column is-12">
                        <b-button
                            tag="router-link"
                            class="is-primary is-outlined is-fullwidth"
                            :to="{
                                name: 'report',
                                params: { lang: $route.params.lang },
                                query: $route.query,
                            }"
                        >
                            {{ $t('report') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <div :key="'general'" class="category-info location">
            <div class="info-content">
                <div class="icon">
                    <img src="@/assets/icons/ic-location-blue.svg" alt="location icon" />
                </div>
                <div class="info-name">{{ house.address_text.full_address }}</div>
            </div>
        </div>
        <b-collapse
            v-for="section of sections.filter(
                (section) => section.items.length || $route.query.showmissing
            )"
            :key="section.name"
            class="card"
            animation="slide"
            :open="openSection == section.name"
            @open="openSection = section.name"
        >
            <template #trigger="props">
                <div class="card-header content level" role="button">
                    <span class="level-left icon-text">
                        <img
                            v-if="section.name !== 'warnings' && section.icon"
                            class="icon is-medium my-2 mx-3"
                            :src="`${publicPath}${section.icon}.svg`"
                        />
                        <span
                            v-if="section.name === 'warnings'"
                            class="icon is-medium my-2 mx-3 has-text-warning"
                        >
                            <i class="mdi mdi-alert mdi-24px"></i>
                        </span>

                        <span class="noselect has-text-weight-bold is-size-6">
                            {{ $t('section.' + section.name) }}
                        </span>
                    </span>
                    <span class="level-right card-header-icon">
                        <span class="mr-4 tag is-rounded">
                            {{ section.items.length }}
                        </span>
                        <i class="arrow" :class="props.open ? 'up' : 'down'"></i>
                    </span>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <section-item
                        v-for="item in section.items"
                        :key="item.name"
                        :item="item"
                        :orientation="'horizontal'"
                    ></section-item>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import AutoComplete from '@/shared/components/AutoComplete.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SectionItem from '@/shared/components/SectionItem.vue'
import { config } from '@/shared/assets/config/config'

export default {
    name: 'Sidebar',
    filters: {
        notnull: function(value) {
            if (value === null || value === undefined) return '-'
            else return value
        },
    },
    components: {
        AutoComplete,
        SectionItem,
    },
    props: {
        house: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            openSection: null,
            publicPath: process.env.BASE_URL,
        }
    },
    computed: {
        ...mapGetters(['getFeatures', 'getValuation']),
        sections() {
            let x = 0
            let sectionNames = [
                'warnings',
                'address_location',
                'c_building',
                'energy',
                'transaction',
                'secondary_buildings',
                'c_parcels',
                'reconstruction_value',
                'economic_activity',
                'roof',
                'flood',
            ]
            let sections = []
            for (const sectionName of sectionNames) {
                let icon = config.filter(
                    (item) => (item.location === 'section') & (item.name === sectionName)
                )[0].icon
                icon = icon ? icon : null
                let sectionContent = {
                    name: sectionName,
                    items: config.filter(
                        (item) =>
                            item.sections.includes(sectionName) &&
                            ((item.location === 'features'
                                ? this.getFeatures[item.name]
                                : this.getValuation[item.name]) ||
                                this.$route.query.showmissing)
                    ),
                    icon: icon,
                }
                sections.push(sectionContent)
            }
            return sections
        },
    },
    methods: {
        ...mapMutations(['UPDATE_ADDRESS']),
        ...mapActions(['updateBuildingId']),
        goBack() {
            this.$router.push({ path: '/' })
        },
        close() {
            this.$emit('close')
        },
        sleep(time) {
            return new Promise((res, rej) => {
                setTimeout(() => {
                    res()
                }, time)
            })
        },
        selectAddress(val) {
            this.UPDATE_ADDRESS({
                streetname: val.streetname,
                streetnumber: val.streetnumber,
                postalcode: val.postalcode,
                municipality: val.municipality,
            })
            this.updateBuildingId(val.building_id)
            let next_action = this.$route.params.action
            if (val.level !== '3' && this.$route.params.action === '3d') {
                next_action = '2d'
            }
            this.$router.push({
                name: 'action',
                params: {
                    lang: this.$route.params.lang,
                    action: next_action,
                },
                query: {
                    building_id: val.building_id,
                    streetname: val.streetname,
                    streetnumber: val.streetnumber,
                    postalcode: val.postalcode,
                    municipality: val.municipality,
                },
            })
        },
    },
}
</script>

<style>
.surfaces {
    width: 22px;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
